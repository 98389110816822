import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FormControl, FormHelperText, InputLabel, MenuItem, Select, ListSubheader
} from '@material-ui/core';

import clsx from 'clsx';
import useLabelWidth from '../../hooks/useLabelWidth';

import styles from './SelectCountries.module.scss';

const SelectCountries = ({
  className, value, name, labelText, helperText, onChange, startAdornment, error, required,
}) => {
  const { t } = useTranslation();
  const [
    labelWidth,
    labelRef,
  ] = useLabelWidth();
  const [countriesArray, setCountriesArray] = useState([]);

  useEffect(() => {
    const countries = [
      {
        code: 'Bitte wählen',
        title: 'Bitte wählen',
      },
      {
        code: 'Baden-Württemberg',
        title: 'Baden-Württemberg',
      },
      {
        code: 'Bayern',
        title: 'Bayern',
      },
      {
        code: 'Berlin',
        title: 'Berlin',
      },
      {
        code: 'Brandenburg',
        title: 'Brandenburg',
      },
      {
        code: 'Bremen',
        title: 'Bremen',
      },
      {
        code: 'Hamburg',
        title: 'Hamburg',
      },
      {
        code: 'Hessen',
        title: 'Hessen',
      },
      {
        code: 'Mecklenburg-Vorpommern',
        title: 'Mecklenburg-Vorpommern',
      },
      {
        code: 'Niedersachsen',
        title: 'Niedersachsen',
      },
      {
        code: 'Nordrhein-Westfalen',
        title: 'Nordrhein-Westfalen',
      },
      {
        code: 'Rheinland-Pfalz',
        title: 'Rheinland-Pfalz',
      },
      {
        code: 'Saarland',
        title: 'Saarland',
      },
      {
        code: 'Sachsen',
        title: 'Sachsen',
      },
      {
        code: 'Sachsen-Anhalt',
        title: 'Sachsen-Anhalt',
      },
      {
        code: 'Schleswig-Holstein',
        title: 'Schleswig-Holstein',
      },
      {
        code: 'Thüringen',
        title: 'Thüringen',
      },
      {
        code: 'separator',
        title: 'separator',
      },
      {
        code: 'Schweiz',
        title: 'Schweiz',
      },
      {
        code: 'Österrreich',
        title: 'Österrreich',
      },
    ];
    // const keys = t('countries', { returnObjects: true });
    // for (const key in keys) {
    //   countries.push({
    //     code: key,
    //     title: keys[key],
    //   });
    // }

    setCountriesArray(countries);
  }, []);

  return (
    <FormControl required={required} className={className} variant="outlined" error={error}>
      <InputLabel ref={labelRef} htmlFor={`input-${name}`}>{labelText}</InputLabel>
      <Select
        value={value || countriesArray[0]?.code || ''}
        id={`input-${name}`}
        name={name}
        onChange={onChange}
        labelWidth={labelWidth}
        startAdornment={startAdornment}
      >
        {countriesArray.map((item) => {
          if(item.code === 'separator') {
            return <ListSubheader><div className={styles.separator} /></ListSubheader>
          }
          return <MenuItem value={item.code}>{item.title}</MenuItem>
        })}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectCountries;
