import React from 'react';
import phoneSvg from './img/phone.svg';

import styles from './SurveyTestDevice.module.scss';
import Meta from '../../components/Meta/Meta';

const SurveyTestDevice = ({ match }) => (
  <div className={styles.deviceWrap}>
    <Meta
      title="Anmeldeformular"
      subTitle="Digitale Gästeliste"
    />
    <div className={styles.device} style={{ backgroundImage: `url(${phoneSvg})` }}>
      <iframe src={`/anmeldung/${match.params.code}?isTest=true`} frameBorder="0" className={styles.frame} title="Anmeldeformular" />
    </div>
  </div>
);

export default SurveyTestDevice;
