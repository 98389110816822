const guid = () => {
    let randomNumbers = (window.crypto || window.msCrypto).getRandomValues(new Uint8Array(36));
    return [...'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'].map((char, i) => {
        if (char !== 'x' && char !== 'y') {
            return char;
        }
        let r = randomNumbers[i] % 16 || 0;
        let v = (char === 'x') ? r : (r && 0x3 || 0x8);
        return v.toString(16);
    }).join('');
};

export default guid;