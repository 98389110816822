import React, { useContext } from 'react';
import moment from 'moment-timezone';
import { makeStyles } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  Link,
  Button
} from '@material-ui/core';
import InboxOutlinedIcon from '@material-ui/icons/InboxOutlined';
import AddIcon from '@material-ui/icons/Add';

import { useTranslation } from 'react-i18next';
import EnhancedTableHead from './components/EnhancedTableHead/EnhancedTableHead';
import useForm from '../../../../hooks/useForm';
import validate from '../../../../helpers/validateAddRespondentForm';
import guid from '../../../../helpers/guid';
import placesContext from "../../../../context/places/placesContext";

const useStyles = makeStyles((theme) => ({
  messageEmpty: {
    textAlign: 'center',
    opacity: 0.4,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '40px 0',
  },
  messageEmptyIcon: {
    fontSize: 80,
  },
  dash: {
    marginLeft: 25
  },
  row: {
    verticalAlign: 'top'
  },
  addButton: {
    marginTop: 10
  },
  addLink: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        alignItems: 'flex-start',
    },
  }
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] === null) {
    return -1;
  }
  if (a[orderBy] === null) {
    return 1;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const LinkAbmelden = ({ id }) => {
  const classes = useStyles();
  const [date, setDate] = React.useState(null);
  const { closeUserRespondentsPlace } = useContext(placesContext);

  const handleCloseRespondent = (id) => {
    closeUserRespondentsPlace(id)
    setDate(new Date())
  }

  return <div>{!date 
    ? <div className={classes.dash}><Link className={classes.addLink} onClick={() => {handleCloseRespondent(id)}}>Abmelden</Link></div>
    : moment.tz(date, moment.tz.guess()).format('DD.MM.YYYY - H:mm')}</div>
}

const RespondentsTable = (props) => {
  const { data, placeId, placeCode, handleSetRespondents, hasCustom, customField } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('creationDate');
  const [showForm, setShowForm] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { addUserRespondentPlace } = useContext(placesContext);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm((resetValues) => {
    const id = guid();
    addUserRespondentPlace({ ...values, visitorRespondentID: id, visitorCode: placeCode});
    handleSetRespondents([{...values, visitorRespondentID: id, completeDate: new Date()}, ...data])
    setShowForm(false);
    resetValues();
  }, validate, Object.assign({
    fullName: '',
    persons: '',
    address: '',
    cityZip: '',
    phone: ''
  }, hasCustom || {custom: ''}));


  const headCells = [
    {
      id: 'completeDate', numeric: false, disablePadding: false, label: <div>Datum und Uhrzeit <br/> bis Datum und Uhrzeit</div>,
    },
    {
      id: 'fullName', numeric: false, disablePadding: false, label: <div>Vorname<br/> Nachname</div>,
    },
    {
      id: 'persons', numeric: false, disablePadding: false, label: 'Begleitung',
    },
    {
      id: 'address', numeric: false, disablePadding: false, label: <div>Strasse<br/> und Hausnummer</div>,
    },
    {
      id: 'cityZip', numeric: false, disablePadding: false, label: <div>PLZ<br/> und Wohnort</div>,
    },
    {
      id: 'phone', numeric: false, disablePadding: false, label: 'Telefonnummer',
    },
  ];

  if(hasCustom) {
    headCells.push({
      id: 'custom', numeric: false, disablePadding: false, label: customField,
    })
  }



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddRespondent = () => {
    setShowForm(true)
  }

  const handleHideForm = () => {
    setShowForm(false)
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data?.length - page * rowsPerPage);

  return (
    <div>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data.length}
            headCells={headCells}
          />
            <TableBody>
              {!!data.length && stableSort(data, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const isItemSelected = isSelected(row.name);
                  return (
                    <TableRow
                      className={classes.row}
                      hover
                      onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell align="left">
                        {row.completeDate && moment.tz(row.completeDate, moment.tz.guess()).format('DD.MM.YYYY - H:mm')}
                        <br/>
                        {row.canceledDate ? moment.tz(row.canceledDate, moment.tz.guess()).format('DD.MM.YYYY - H:mm') : <LinkAbmelden id={row.visitorRespondentID}/>}
                      </TableCell>
                      <TableCell align="left">{row.fullName}</TableCell>
                      <TableCell align="left">{row.persons}</TableCell>
                      <TableCell align="left">{row.address}</TableCell>
                      <TableCell align="left">{row.cityZip}</TableCell>
                      <TableCell align="left">{row.phone}</TableCell>
                      {hasCustom && <TableCell align="left">{row.custom}</TableCell>}
                    </TableRow>
                  );
                })
              }


                {!data.length && (
                  <TableRow>
                    <TableCell colSpan={headCells.length}>
                      <div className={classes.messageEmpty}>
                        <InboxOutlinedIcon className={classes.messageEmptyIcon} />
                        {t('tableComponent.empty')}
                      </div>
                    </TableCell>
                  </TableRow>
                )}


                {showForm && <TableRow
                      className={classes.row}
                      hover
                      role="checkbox"
                      tabIndex={-1}
                    >
                      <TableCell align="left">
                      </TableCell>
                      <TableCell align="left">
                        <TextField 
                          fullWidth
                          onChange={handleChange} 
                          value={values.fullName || ''} 
                          name="fullName" 
                          error={!!errors.fullName}
                        />
                        </TableCell>
                      <TableCell align="left">
                        <TextField 
                          fullWidth
                          onChange={handleChange} 
                          value={values.persons || ''} 
                          name="persons" 
                          error={!!errors.persons}
                        />
                        </TableCell>
                      <TableCell align="left">
                        <TextField 
                          fullWidth
                          onChange={handleChange} 
                          value={values.address || ''} 
                          name="address" 
                          error={!!errors.address}
                        />
                        </TableCell>
                      <TableCell align="left">
                        <TextField 
                          fullWidth
                          onChange={handleChange} 
                          value={values.cityZip || ''} 
                          name="cityZip" 
                          error={!!errors.cityZip}
                        />
                        {!hasCustom && <Button
                          onClick={handleHideForm}
                          className={classes.addButton}
                          fullWidth
                          size="small"
                          variant="contained"
                        >Abbrechen</Button>}
                        </TableCell>
                      <TableCell align="right">
                        <TextField 
                          fullWidth
                          onChange={handleChange} 
                          value={values.phone || ''} 
                          name="phone"
                          error={!!errors.phone}
                        />
                        {hasCustom ? <Button
                          onClick={handleHideForm}
                          className={classes.addButton}
                          fullWidth
                          size="small"
                          variant="contained"
                        >Abbrechen</Button> : <Button
                          disabled={!!Object.keys(errors).length}
                          className={classes.addButton}
                          fullWidth
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                        >SPEICHERN</Button>}
                        </TableCell>
                        {hasCustom && <TableCell align="right">
                        <TextField
                          fullWidth
                          onChange={handleChange}
                          value={values.custom || ''}
                          name="custom"
                          error={!!errors.custom}
                        />
                        <Button
                          disabled={!!Object.keys(errors).length}
                          className={classes.addButton}
                          fullWidth
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                        >SPEICHERN</Button>
                      </TableCell>}
                    </TableRow>}
                {!showForm && <TableRow>
                  <TableCell colSpan={headCells.length} align="left">
                    <Link className={classes.addLink} onClick={handleAddRespondent}>
                      <AddIcon className={classes.qrLinkIco}/>
                      Neuen Gast manuell anmelden
                    </Link>
                  </TableCell>
                </TableRow>}
            </TableBody>
        </Table>
      </TableContainer>
      {!!data.length && (
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        labelRowsPerPage="Zeilen pro Seite:"
      />
      )}
    </div>
  );
};

export default RespondentsTable;
