import i18n from 'i18next';

const validate = (values) => {
  const errors = {};

  for (const value in values) {
    if (value === 'fullName'
        || value === 'address'
        || value === 'cityZip'
        || value === 'phone') {
      if (!values[value]?.trim()?.length) {
        errors[value] = i18n.t('form.error-required');
      }
    }
  }
  return errors;
};

export default validate;
