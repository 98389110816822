import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import {
  getUserRewards,
  postUserRewards,
  rewardExportPDF,
} from '../../api/rewards';

import rewardReducer from './rewardReducer';
import RewardContext from './rewardContext';
import {
  GET_REWARDS, HIDE_LOADER, SHOW_LOADER, SHOW_LOADER_PDF, HIDE_LOADER_PDF,
} from '../types';

const RewardState = ({ children }) => {
  const initialState = {
    data: [],
    loading: false,
    loadingDownloadPDF: false,
  };

  const [state, dispatch] = useReducer(rewardReducer, initialState);

  const showLoader = () => dispatch({ type: SHOW_LOADER });
  const hideLoader = () => dispatch({ type: HIDE_LOADER });
  const showLoaderPDF = () => dispatch({ type: SHOW_LOADER_PDF });
  const hideLoaderPDF = () => dispatch({ type: HIDE_LOADER_PDF });
  const getRewards = async () => {
    const res = await getUserRewards();
    dispatch({ type: GET_REWARDS, payload: res.data.data });
  };

  const postRewards = async (total) => {
    showLoader();
    await postUserRewards(total);
    getRewards();
    hideLoader();
  };

  const downloadRewardPDF = async (total) => {
    const res = await rewardExportPDF(total);
    const blob = new Blob([res.data]);
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = res.headers['content-disposition'].split('filename=')[1];
    link.click();
  };

  return (
    <RewardContext.Provider value={{
      getRewards,
      postRewards,
      downloadRewardPDF,
      showLoaderPDF,
      hideLoaderPDF,
      data: state.data,
      loading: state.loading,
      loadingDownloadPDF: state.loadingDownloadPDF,
    }}
    >
      {children}
    </RewardContext.Provider>
  );
};

RewardState.propType = {
  children: PropTypes.element.isRequired,
};

export default RewardState;
