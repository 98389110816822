import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { useTranslation } from 'react-i18next';
import AuthContext from './context/auth/authContext';
import AlertContext from './context/alert/alertContext';

import MainAuthContainer from './pages/MainAuthContainer/MainAuthContainer';
import MainContainer from './pages/MainContainer/MainContainer';
import Dashboard from './pages/Dashboard/Dashboard';
import Account from './pages/Account/Account';
import SignIn from './pages/SignIn/SignIn';
import Registration from './pages/Registration/Registration';
import ForgotPass from './pages/ForgotPass/ForgotPass';
import ResetPass from './pages/ResetPass/ResetPass';
import ConfirmEmail from './pages/ConfirmEmail/ConfirmEmail';
import QRCode from './pages/QRCode/QRCode';
import Survey from './pages/Survey/Survey';
import NotFound from './pages/NotFound/NotFound';
import SurveyTestDevice from './pages/SurveyTestDevice/SurveyTestDevice';

import Preloader from './components/Preloader/Preloader';

const PrivateRoute = ({ component: Component, ...props }) => {
  const { userAuth } = useContext(AuthContext);
  return (
    <Route
      {...props}
      render={() => (userAuth === true
        ? <MainContainer><Component {...props} /></MainContainer>
        : <Redirect to={{ pathname: '/sign-in', state: { from: props.location } }} />)}
    />
  );
};

const PublicRoute = ({ component: Component, ...props }) => {
  const { userAuth } = useContext(AuthContext);
  return (
    <Route
      {...props}
      render={() => (userAuth === false
        ? <MainAuthContainer><Component {...props} /></MainAuthContainer>
        : <Redirect to={{ pathname: '/', state: { from: props.location } }} />)}
    />
  );
};

function App() {
  const { t } = useTranslation();
  const {
    checkAuth, userCheck, preLoading, userInfo
  } = useContext(AuthContext);
  const {
    isShowAlert, messageAlert, typeAlert, closesAlert,
  } = useContext(AlertContext);

  useEffect(() => {
    if(window.location.pathname.indexOf('anmeldung') === -1
        && window.location.pathname.indexOf('survey-test-device') === -1) {
      checkAuth();
    }
  }, []);

  return (
    <BrowserRouter>
      <Preloader show={preLoading} />
      <Snackbar
        open={isShowAlert}
        autoHideDuration={4000}
        onClose={closesAlert}
      >
        <Alert elevation={6} variant="filled" severity={typeAlert}>{messageAlert}</Alert>
      </Snackbar>
      <Switch>
        <PublicRoute path="/sign-in" component={SignIn} />
        <PublicRoute path="/register" component={Registration} />
        <PublicRoute path="/forgot-pass" component={ForgotPass} />
        <PublicRoute path="/reset-pass" component={ResetPass} />
        <Route path="/confirm-email" render={() => (<MainAuthContainer><ConfirmEmail /></MainAuthContainer>)} />
        <Route path="/anmeldung/:code" component={Survey} />
        <Route path="/survey-test-device/:code" component={SurveyTestDevice} />
        {userCheck && (<PrivateRoute path="/" exact component={Dashboard} />)}
        {userCheck && (<PrivateRoute path="/account" component={Account} />)}
        {userCheck && (<PrivateRoute path="/qr-codes" component={QRCode} />)}
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}

PrivateRoute.propType = {
  component: PropTypes.element.isRequired,
  location: PropTypes.string,
};

PublicRoute.propType = {
  component: PropTypes.element.isRequired,
};

export default App;
