import { post, put, get } from './restApi';

const rewardApiUrl = process.env.REACT_APP_REPORT_API_URL;

export const userLogin = (data) => post('Account/Login', { ...data, grant_type: 'password' });

export const registerExternalToken = (data) => post('Account/RegisterExternalToken', { ...data });

export const userRegister = (data) => post('Account/Register', { ...data });

export const userLogOut = () => post('Account/Session/Logout');

export const recoverPassword = (data) => post('Account/ForgotPassword', { ...data, callbackUrl: window.location.origin + '/reset-pass' });

export const resetPassword = (data) => put('Account/ResetPassword', { ...data });

export const checkUserAuth = () => put('Session/Sessions/HeartBeat');

export const getUserInfo = () => get('Visitor/Visitors/Visitor');

export const updateUserInfo = (data) => put('Visitor/Visitors/UpdateVisitorInfo', { ...data });

export const externalLogins = () => get('Account/ExternalLogins?returnUrl=/');

export const confirmEmail = (code, userid) => put('Account/ConfirmEmail', { code, userid });

export const reSendConfirmEmail = () => post('Account/ReSendConfirmEmail', `\"${window.location.origin}/confirm-email\"`, {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
});

export const piplineExportXLSX = () => get(`${rewardApiUrl}Visitor/Visitors/Pipeline/XLSX`, { responseType: 'blob' });

export const qrUpload = (data) => post(`Visitor/Visitors/Upload`, data);
