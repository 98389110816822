import React, {useContext, useLayoutEffect, useState} from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography, Paper} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import authContext from '../../context/auth/authContext';
import placesContext from '../../context/places/placesContext';
import Meta from '../../components/Meta/Meta';
import RespondentsVis from './components/RespondentsVis/RespondentsVis';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import styles from "./Dashboard.module.scss";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    listTitle: {
        marginBottom: 30
    },
    headingDesc: {
        color: 'rgba(0, 0, 0, .4)',
        fontSize: 14,
        paddingTop: 10,
        paddingBottom: 6,
        marginLeft: 20,
        position: 'absolute',
        top: 30
    },
    accordionSummary: {
        minHeight: 83,
        flexDirection: 'row-reverse',
        alignItems: 'self-start',
        '&.no-desc': {
            alignItems: 'center',
        },
        '&.Mui-expanded': {
            //minHeight: 'auto',
        },
        '&.Mui-expanded .headingDesc': {
            display: 'none',
        },
        '&.Mui-expanded .MuiAccordionSummary-content': {
            margin: '12px 0',
        }
    },
    headingTitle: {
        marginLeft: 20,
    },
    summaryContentWrap: {
        display: 'flex',
        margin: '-5px 0',
        width: '100%'
    },
    summaryContentNum: {
        marginRight: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    summaryContentBody: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%'
    },
}));

const RespondentsAccordion = ({ item, index }) => {
    const classes = useStyles();
    const [ openAccordion, setOpenAccordion] = useState(false);
    return (<Accordion defaultExpanded={index === 0} onChange={(e, exp) => setOpenAccordion(exp)}>
        <AccordionSummary
            className={clsx(classes.accordionSummary, {'no-desc': !item.description})}
            expandIcon={<ExpandMoreIcon />}
        >
            <div className={classes.summaryContentWrap}>
                <div className={classes.summaryContentBody}>
                    <Typography variant="h6" component="h2" className={classes.headingTitle}>{item.name || 'Neue Betriebsstätte'}</Typography>
                    {item.description && <Typography className={clsx('headingDesc', classes.headingDesc) }>{item.description}</Typography>}
                </div>
            </div>
        </AccordionSummary>
        <AccordionDetails>
            <div className={styles.respondentsWrap}>
                <RespondentsVis place={item} openAccordion={openAccordion} index={index}/>
            </div>
        </AccordionDetails>
    </Accordion>)
}

const Dashboard = withRouter(( { location } ) => {
  const classes = useStyles();
  const { data: places, getPlaces } = useContext(placesContext);
  const { downloadPiplineExportXLSX, XMLLoading, userInfo } = useContext(authContext);

  useLayoutEffect(() => {
      getPlaces();
  }, []);

  if(!userInfo.company.length &&
      (location?.state?.from.pathname === '/sign-in' || location?.state?.from.pathname === '/register')
  ) {
      return <Redirect to='/account'/>;
  }

  return (
    <Grid container spacing={4} wrap="wrap">
      <Meta
        title="Meine Gäste"
        subTitle="Digitale Gästeliste"
      />
      <Grid item xs={12}>

          {places.length === 1 &&
            <Paper className={styles.paper}>
              <Typography align="center" variant="h6" component="h2" gutterBottom>Meine Gäste</Typography>
              <RespondentsVis place={places[0]} index={0}/>
            </Paper>}
          {places.length > 1 && <Typography align="center" variant="h6" component="h2" gutterBottom className={classes.listTitle}>Meine Gäste</Typography>}
          {places.length > 1 && places.map((item, index) => <RespondentsAccordion item={item} index={index}/>)}
      </Grid>
    </Grid>
  );
});

export default Dashboard;
