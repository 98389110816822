import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import {
  getUserPlaces,
  newUserPlaces,
  qrUpload,
  updateUserPlaces,
  getRespondentsPlace,
  piplinePlaceExportXLSX,
  deleteUserPlaces,
  addRespondentsPlace,
  closeRespondentsPlace
} from '../../api/places';

import placesReducer from './placesReducer';
import PlacesContext from './placesContext';
import {
  GET_PLACES, HIDE_LOADER, SHOW_LOADER, NEW_PLACE, SHOW_LOADER_PLACE, HIDE_LOADER_PLACE, UPDATE_PLACES, RESET_OPEN_LAST
} from '../types';

const PlacesState = ({ children }) => {
  const initialState = {
    data: [],
    placesRespondents: [],
    loading: false,
    loadingAddPlace: false,
    openLast: false,
  };

  const [state, dispatch] = useReducer(placesReducer, initialState);

  const showLoader = () => dispatch({ type: SHOW_LOADER });
  const hideLoader = () => dispatch({ type: HIDE_LOADER });

  const showLoaderPlace = () => dispatch({ type: SHOW_LOADER_PLACE });
  const hideLoaderPlace = () => dispatch({ type: HIDE_LOADER_PLACE });

  const getPlaces = async () => {
    const res = await getUserPlaces();
    if(!res.data.data.length) {
      newPlace({});
    } else {
      dispatch({ type: GET_PLACES, payload: res.data.data });
    }
  };

  const newPlace = async (data) => {
    showLoaderPlace();
    const res = await newUserPlaces(data);
    dispatch({ type: NEW_PLACE, payload: res.data.data });
    hideLoaderPlace();
    return res;
  };

  const updatePlaces = async (data) => {
    await updateUserPlaces(data);
  };

  const updatePlacesNoSave = (data, index) => {
    dispatch({ type: UPDATE_PLACES, payload: {data, index} });
  };

  const userQRCodeUpload = async (file, id) => {
    await qrUpload(file, id);
  };

  const getPlacesRespondents = async () => {
    await getPlaces()
  };

  const getUserRespondentsPlace = async (id) => {
    return await getRespondentsPlace(id)
  };

  const addUserRespondentPlace = async (data) => {
    await addRespondentsPlace(data);
  };

  const getpiplinePlaceExportXLSX = async (id) => {
    try {
      const res = await piplinePlaceExportXLSX(id);
      const blob = new Blob([res.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const disposition = res.headers['content-disposition'];
      if (disposition) {
        const regex = /filename\*=(?:utf-8'')?(.*)/gi;
        const matches = regex.exec(disposition);
        if (matches.length > 1) {
          link.download = decodeURI(matches[1]);
        }
      }
      link.click();
    } catch (e) {      
    }
  };

  const deletePlace = async (id) => {
    await deleteUserPlaces(id);
    await getPlaces();
  }

  const resetOpenLast = () => {
    dispatch({ type: RESET_OPEN_LAST });
  }

  const closeUserRespondentsPlace = (id) => {
    closeRespondentsPlace(id);
  }

  return (
    <PlacesContext.Provider value={{
      getPlaces,
      userQRCodeUpload,
      updatePlaces,
      newPlace,
      updatePlacesNoSave,
      getPlacesRespondents,
      getUserRespondentsPlace,
      getpiplinePlaceExportXLSX,
      deletePlace,
      resetOpenLast,
      addUserRespondentPlace,
      closeUserRespondentsPlace,
      data: state.data,
      placesRespondents: state.placesRespondents,
      loading: state.loading,
      loadingAddPlace: state.loadingAddPlace,
      openLast: state.openLast,
    }}
    >
      {children}
    </PlacesContext.Provider>
  );
};

PlacesState.propType = {
  children: PropTypes.element.isRequired,
};

export default PlacesState;
