import {
  GET_PLACES, HIDE_LOADER, SHOW_LOADER, NEW_PLACE, SHOW_LOADER_PLACE, HIDE_LOADER_PLACE, UPDATE_PLACES, RESET_OPEN_LAST
} from '../types';

const handlers = {
  [SHOW_LOADER]: (state) => ({ ...state, loading: true }),
  [HIDE_LOADER]: (state) => ({ ...state, loading: false }),
  [SHOW_LOADER_PLACE]: (state) => ({ ...state, loadingAddPlace: true }),
  [HIDE_LOADER_PLACE]: (state) => ({ ...state, loadingAddPlace: false }),
  [GET_PLACES]: (state, { payload }) => ({
    ...state,
    loading: false,
    data: payload,
  }),
  [NEW_PLACE]: (state, { payload }) => ({
    ...state,
    data: [...state.data, payload],
    openLast: true
  }),
  [RESET_OPEN_LAST]: (state) => ({
    ...state,
    openLast: false
  }),
  [UPDATE_PLACES]: (state, { payload }) => {
    let newData = state.data;
    newData[payload.index] = payload.data
    return {
      ...state,
      loading: false,
      data: newData,
    }
  },
};

const placesReducer = (state, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state, action);
};

export default placesReducer;
