import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import {
  getUserCustomers,
  getUserTotal,
  getUserRespondents
} from '../../api/customers';

import customersReducer from './customersReducer';
import CustomersContext from './customersContext';
import { GET_CUSTOMERS, GET_TOTAL, GET_RESPONDENTS } from '../types';

const CustomersState = ({ children }) => {
  const initialState = {
    data: [],
    dataRespondents: [],
    totals: {},
  };

  const [state, dispatch] = useReducer(customersReducer, initialState);

  const getCustomers = async () => {
    const res = await getUserCustomers();
    dispatch({ type: GET_CUSTOMERS, payload: res.data.data });
  };
  const getRespondents = async () => {
    const res = await getUserRespondents();
    dispatch({ type: GET_RESPONDENTS, payload: res.data.data });
  };

  const getTotal = async () => {
    const res = await getUserTotal();
    dispatch({ type: GET_TOTAL, payload: res.data.data });
  };


  return (
    <CustomersContext.Provider value={{
      getCustomers,
      getTotal,
      getRespondents,
      data: state.data,
      dataRespondents: state.dataRespondents,
      totals: state.totals,
    }}
    >
      {children}
    </CustomersContext.Provider>
  );
};

CustomersState.propType = {
  children: PropTypes.element.isRequired,
};

export default CustomersState;
