import React, {useEffect, useState} from 'react'
import { SketchPicker  } from 'react-color'
import styles from './ColorButton.module.scss'

const popover = {
  position: 'absolute',
  zIndex: '2',
}
const cover = {
  position: 'fixed',
  top: '0px',
  right: '0px',
  bottom: '0px',
  left: '0px',
}

const ColorButton = ({ onChange, userColor }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const [color, setColor] = useState(false)

  useEffect(() => {
    setColor(userColor);
  }, [userColor])

  const handleChangeComplete = (color) => {
    onChange(color.hex);
    setColor(color.hex);
  };

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker)
  };

  const handleClose = () => {
    setDisplayColorPicker(false)
  };

  return (
      <div className={styles.main}>
        <div className={styles.colorButton} >
          <div style={{ background: !!color ? color : '#000' }} className={styles.colorLabel} onClick={ handleClick }/>
          <div>Farbe Auswählen</div>
        </div>
        { displayColorPicker ? <div style={ popover }>
          <div style={ cover } onClick={ handleClose }/>
          <SketchPicker onChange={ handleChangeComplete } color={userColor}/>
        </div> : null }
      </div>
  )
}

export default ColorButton