import React, {
  useEffect, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  userLogin,
  userLogOut,
  checkUserAuth,
  getUserInfo,
  updateUserInfo,
  userRegister,
  recoverPassword,
  resetPassword,
  registerExternalToken,
  externalLogins,
  reSendConfirmEmail,
  confirmEmail,
  piplineExportXLSX,
  qrUpload
} from '../../api/auth';

import authReducer from './authReducer';
import AuthContext from './authContext';
import {
  USER_LOGIN,
  USER_LOGOUT,
  SHOW_LOADER,
  HIDE_LOADER,
  SHOW_PRELOADER,
  USER_INFO,
  USER_UPDATE,
  USER_CHANGE_LANG,
  GET_EXTERNAL_LOGIN,
  SHOW_XML_LOADER,
  HIDE_XML_LOADER,
  SET_COOKIE_ERROR
} from '../types';
import {rewardExportPDF} from "../../api/rewards";

const AuthState = (props) => {
  const { children } = props;
  const initialState = {
    userAuth: false,
    loading: false,
    preLoading: false,
    XMLLoading: false,
    userCheck: false,
    externalLogins: [],
    cookieError: false,
    userInfo: {
      card: null,
      email: '',
      logo: null,
      language: '',
      company: '',
      bundesland: '',
      color: '',
      websiteFaculty: '',
      paymentMethod: '',
      additionalAddress: '',
      additionalCity: '',
      additionalCompany: '',
      additionalCountry: '',
      additionalEmail: '',
      additionalFirstName: '',
      additionalLastName: '',
      additionalPhone: '',
      additionalPostalCode: '',
      partnerCode: '',
    },
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  const { i18n } = useTranslation();

  const showLoader = () => dispatch({ type: SHOW_LOADER });
  const hideLoader = () => dispatch({ type: HIDE_LOADER });
  const showXMLLoader = () => dispatch({ type: SHOW_XML_LOADER });
  const hideXMLLoader = () => dispatch({ type: HIDE_XML_LOADER });
  const showPreLoader = () => dispatch({ type: SHOW_PRELOADER });

  const changeLang = (lang) => {
    dispatch({ type: USER_CHANGE_LANG, payload: 'de' });
  };

  const initialLang = () => {
    changeLang(i18n.language);
  };

  useEffect(() => {
    initialLang();
  }, []);

  const getUser = async () => {
    try {
      const res = await getUserInfo();
      dispatch({ type: USER_INFO, payload: res.data.data });
    } catch (e) {
      hideLoader();
    }
  };

  const login = async (email, password) => {
    showLoader();
    showPreLoader();
    const res = await userLogin({ email, password });
    if (res.data.success) {
      await getUser();
      await initialLang();
      dispatch({ type: USER_LOGIN });
    } else {
      dispatch({ type: USER_LOGOUT });
    }
    return res;
  };

  const register = async (email, password, iAcceptTermOfService) => {
    showLoader();
    showPreLoader();
    const res = await userRegister({
      email, password, iAcceptTermOfService, confirmPassword: password,
    });
    if (res.data.success) {
      await getUser();
      dispatch({ type: USER_LOGIN });
    } else {
      dispatch({ type: USER_LOGOUT });
    }
    return res;
  };

  const recover = async (email) => {
    showLoader();
    return recoverPassword({ email }).then((data) => {
      hideLoader();
      return data;
    });
  };

  const reset = async (code, email, password) => {
    showLoader();
    return resetPassword({
      code, email, password, confirmPassword: password,
    }).then((data) => {
      hideLoader();
      return data;
    });
  };

  const logOut = async () => {
    showLoader();
    window.location.href = `${process.env.REACT_APP_API_URL}Account/Session/Logout?returnUrl=${window.location.origin}/sign-in`;
  };

  const checkAuth = async () => {
    showPreLoader();
    try {
      const res = await checkUserAuth();
      if (res.data.success) {
        await getUser();
        dispatch({ type: USER_LOGIN });
      } else {
        dispatch({ type: USER_LOGOUT });
      }
    } catch (e) {
      dispatch({ type: USER_LOGOUT });
    }
  };

  const updateUser = async (data) => {
    dispatch({ type: USER_UPDATE, payload: data });
  };

  const saveUser = async (values = {}) => {
    showLoader();
    try {
      await updateUserInfo({ ...state.userInfo, ...values });
      updateUser(values);
      hideLoader();
    } catch (e) {
      hideLoader();
    }
  };

  const googleLogin = async (data) => {
    showLoader();
    try {
      const res = await registerExternalToken({ email: data.profileObj.email, token: data.accessToken, provider: 'Google' });
      if (res.data.access_token) {
        await getUser();
        await initialLang();
        dispatch({ type: USER_LOGIN });
      } else {
        dispatch({ type: USER_LOGOUT });
      }
      return res;
    } catch (e) {
      hideLoader();
      return e;
    }
  };

  const fbLogin = async (data) => {
    showLoader();
    if (data.accessToken) {
      try {
        const res = await registerExternalToken({ email: data.email, token: data.accessToken, provider: 'Facebook' });
        if (res.data.access_token) {
          await getUser();
          await initialLang();
          dispatch({ type: USER_LOGIN });
        } else {
          dispatch({ type: USER_LOGOUT });
        }
        return res;
      } catch (e) {
        hideLoader();
        return e;
      }
    }
    hideLoader();
    return null;
  };

  const getExternalLogins = async () => {
    showLoader();
    const res = await externalLogins();
    dispatch({ type: GET_EXTERNAL_LOGIN, payload: res.data });
  };

  const reSendConfirmUserEmail = async () => {
    showLoader();
    const res = await reSendConfirmEmail();
    hideLoader();
    return res;
  };

  const confirmUserEmail = async (code, userid) => {
    showLoader();
    const res = await confirmEmail(code, userid);
    hideLoader();
    return res;
  };

  const downloadPiplineExportXLSX = async () => {
    showXMLLoader();
    try {
      const res = await piplineExportXLSX();
      const blob = new Blob([res.data]);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      const disposition = res.headers['content-disposition'];
      if (disposition) {
        const regex = /filename\*=(?:utf-8'')?(.*)/gi;
        const matches = regex.exec(disposition);
        if (matches.length > 1) {
          link.download = decodeURI(matches[1]);
        }
      }
      hideXMLLoader();
      link.click();
    } catch (e) {
      hideXMLLoader();
    }
  };

  const userQRCodeUpload = async (file) => {
    const res = await qrUpload(file);
  };

  const setCookieError = () => {
    dispatch({ type: SET_COOKIE_ERROR });
  };

  return (
    <AuthContext.Provider value={{
      showLoader,
      login,
      register,
      recover,
      reset,
      logOut,
      checkAuth,
      getUser,
      updateUser,
      saveUser,
      changeLang,
      googleLogin,
      getExternalLogins,
      reSendConfirmUserEmail,
      confirmUserEmail,
      fbLogin,
      downloadPiplineExportXLSX,
      userQRCodeUpload,
      setCookieError,
      userAuth: state.userAuth,
      loading: state.loading,
      XMLLoading: state.XMLLoading,
      logo: state.userInfo.logo,
      preLoading: state.preLoading,
      userInfo: state.userInfo,
      userCheck: state.userCheck,
      externalLogins: state.externalLogins,
      cookieError: state.cookieError,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthState.propType = {
  children: PropTypes.element.isRequired,
};

export default AuthState;
