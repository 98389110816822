import React, {useEffect, useState} from 'react';
import { useDropzone } from 'react-dropzone';
import clsx from 'clsx';
import { Link } from '@material-ui/core';

import styles from './UploadDropzone.module.scss'

const UploadDropzone = ({ onChange, defaultImage }) => {
    const [fileUrl, setFileUrl] = useState(null);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles
    } = useDropzone({
        accept: 'image/jpeg, image/png',
        onDrop: (e) => {
            onChange(e)
            setFileUrl(URL.createObjectURL(e[0]))
        }
    });

    useEffect(() => {
        setFileUrl(defaultImage)
    }, [defaultImage])

    const handleClear = () => {
        setFileUrl(null)
        onChange(null)
    }

    return (
        <div className={styles.container}>
            {fileUrl && <div className={styles.imgWrap}><img src={fileUrl} alt=""/></div>}
            {fileUrl && <div className={styles.imgClear}><Link onClick={handleClear}>Logo entfernen</Link></div>}
            <div {...getRootProps({className: clsx(styles.dropzone, isDragAccept && styles.dropzoneActive)})}>
                <input {...getInputProps()} />
                {isDragAccept && (<p>Logo hochladen</p>)}
                {isDragReject && (<p>Es können nur Bilder im PNG- oder JPG-Format hochgeladen werden</p>)}
                {!isDragActive && (<p>Ziehen Sie das Logo-Bild hierher oder klicken Sie hier, um es auszuwählen ...</p>)}
            </div>

        </div>
    );
}

export default UploadDropzone;