import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Meta from '../../components/Meta/Meta';
import authContext from '../../context/auth/authContext';
import useQuery from '../../hooks/useQuery';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  divider: {
    marginBottom: '10px',
  },
  gridLang: {
    textAlign: 'right',
  },
  selctLang: {
    fontSize: '12px',
  },
  errorMess: {
    padding: theme.spacing(2),
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 0,
    color: theme.palette.error.main,
    fontSize: 14,
  },
}));

const ConfirmEmail = () => {
  const { t } = useTranslation();
  const { confirmUserEmail, loading } = useContext(authContext);
  const classes = useStyles();
  const query = useQuery();

  const handleConfirm = () => {
    if (query.get('code') && query.get('userid')) {
      confirmUserEmail(query.get('code').replace(
        new RegExp(/ /g), '+',
      ), query.get('userid')).then((data) => {
        if (data.data.success) {
          window.location.href = '/';
        }
      });
    }
  };

  return (
    <div className={classes.paper}>
      <Meta
        title={t('meta.confirm-email')}
        subTitle="Digitale Gästeliste"
      />
      <Typography variant="body2" color="textSecondary" align="center">
        {t('confirmEmailPage.message')}
      </Typography>
      <br />
      <br />
      <Button
        onClick={handleConfirm}
        variant="contained"
        color="primary"
        disabled={loading}
      >
        {t('confirmEmailPage.button-confirm')}
      </Button>
    </div>

  );
};

export default ConfirmEmail;
