import React, { useContext } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import styles from './NotFound.module.scss';
import LogoIcon from '../../components/Icons/Icons';
import AuthContext from '../../context/auth/authContext';

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#e31c5e!important',
    },
  },
})(() => null);

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '20px',
    padding: '24px 38px',
    marginBottom: theme.spacing(2),
  },
  logo: {
    fontSize: '86px',
    marginBottom: '24px',
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  link: {
    color: '#fff',
    textDecoration: 'underline',
  },
}));

const NotFound = withRouter((props) => {
  const classes = useStyles();
  const { loading } = useContext(AuthContext);
  const { t } = useTranslation();

  return (
    <main className={styles.main}>
      {loading && <LinearProgress className={classes.progressBar} variant="query" color="primary" />}
      <Container component="main" maxWidth="sm">
        <div className={styles.logo}>
          <a href="/">
            <LogoIcon
              className={classes.logo}
              htmlColor="#fff"
              fontSize="large"
            />
          </a>
          <div>QUESTIONSTAR</div>
          <div className={styles.logo_desc}>Digitale Gästeliste</div>
        </div>


        <div className={styles['not-found-wrapper']}>
          <Typography variant="h1" component="h1" gutterBottom color="initial">404</Typography>
          <Typography variant="subtitle1" component="h6" gutterBottom color="initial">Die von Ihnen angefragte Webseite existiert nicht.</Typography>
          <br />
          <br />
          <div>
            <a href="/sign-in">Zum Login</a>
            <br />
            <br />
            <a href="https://www.questionstar.de/besucheranmeldung/">Zur Anmedlung</a>
          </div>
        </div>



      </Container>
    </main>
  );
});

NotFound.propType = {
  children: PropTypes.element.isRequired,
};

export default NotFound;
