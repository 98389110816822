import React, {useContext, useEffect, useLayoutEffect, useState, useRef} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Link,
  Tooltip,
  Fab,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import CloseSharpIcon from '@material-ui/icons/CloseSharp';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import canvasToImage from 'canvas-to-image';
import QRCodeGen from "qrcode.react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {NavLink} from "react-router-dom";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import {useDebounce} from "@react-hook/debounce";
import {makeStyles} from "@material-ui/core/styles";
import Input from "../../../../components/Input/Input";
import ColorButton from "../../../../components/ColorButton/ColorButton";
import UploadDropzone from "../../../../components/UploadDropzone/UploadDropzone";
import styles from "../../QRCode.module.scss";
import placesContext from "../../../../context/places/placesContext";
import authContext from "../../../../context/auth/authContext";
import { FormatListBulletedTwoTone } from '@material-ui/icons';


const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: 200,
    padding: '24px 60px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '14px 20px',
    },
  },
  divider: {
    marginBottom: 20,
  },
  desc: {

  },
  grid: {
    marginBottom: 20,
  },
  gridInputWrap: {
    paddingBottom: '0!important',
    [theme.breakpoints.down('sm')]: {
      order: 1
    },
  },
  gridOptionsWrap: {
    [theme.breakpoints.down('sm')]: {
      order: 3
    },
  },
  qrGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      order: 2,
      alignItems: 'flex-start',
    },
  },
  gridLink: {
    paddingTop: 40
  },
  button: {
    width: 260
  },
  buttonSave: {
    position: 'relative'
  },
  qrLink: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  qrLinkIco: {
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: 14
  },
  accordion: {

  },
  accordionSummary: {
    minHeight: 83,
    flexDirection: 'row-reverse',
    alignItems: 'self-start',
    '&.no-desc': {
      alignItems: 'center',
    },
    '&.Mui-expanded': {
      alignItems: 'center',
     // minHeight: 'auto',
    },
    '&.Mui-expanded .headingDesc': {
      display: 'none',
    },
    '&.Mui-expanded .MuiAccordionSummary-content': {
      margin: '12px 0',
    }
  },
  headingDesc: {
    color: 'rgba(0, 0, 0, .4)',
    fontSize: 14,
    paddingTop: 10,
    paddingBottom: 6,
    marginLeft: 20,
    position: 'absolute',
    top: 30
  },
  summaryContentWrap: {
    display: 'flex',
    margin: '-5px 0',
    width: '100%'
  },
  summaryContentNum: {
    marginRight: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  summaryContentBody: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  bodyWrap: {
    position: 'relative'
  },
  descWrap: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 40,
  },
  buttonDelete: {
    marginLeft: 'auto',
    flex: '0 0 40px'
  },
  headingTitle: {
    marginLeft: 20,
  },
  blurring: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: 'rgba(255,255,255,.7)'
  },
  expireLabel: {
    height: 64
  },
  expireInput: {
    width: 72,
    marginRight: 14
  },
  expireInputDesc: {
    fontSize: '1rem'
  },
  customLabel: {
    marginBottom: 20
  }
}));

const QRForm = (props) => {
  const { code, logo, name, color, description, index, accordion, visitorPlaceID, isLast, expired, hasCustom, custom } = props;
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:960px)');
  const [place, setPlace] = useState(props);
  const [fileUrl, setFileUrl] = useState(null)
  const [fileImage, setFileImage] = useState(null)
  const [bluring, setBluring] = useState(false)

  const [accordionOpen, setAccordionOpen] = useState(false)

  const [openDialog, setOpenDialog] = useState(false);

  const [nameDebounce, setNameDebounce] = useDebounce('', 1000)
  const [tmpName, setTmpName] = useState('')

  const [descriptionDebounce, setDescriptionDebounce] = useDebounce('', 1000)
  const [tmpDescription, setTmpDescription] = useState('')

  const [colorDebounce, setColorDebounce] = useDebounce('', 1000)
  const [tmpColor, setTmpColor] = useState('')

  const [expiredDebounce, setExpiredDebounce] = useDebounce('', 1000)
  const [tmpExpireTime, setTmpExpireTime] = useState('')

  const [hasCustomDebounce, setHasCustomDebounce] = useDebounce(false, 1000)
  const [tmpHasCustom, setTmpHasCustom] = useState(false)

  const [customDebounce, setCustomDebounce] = useDebounce('', 1000)
  const [tmpCustom, setTmpCustom] = useState('')

  const { updatePlaces, userQRCodeUpload, deletePlace, openLast, resetOpenLast } = useContext(placesContext)
  const { userInfo } = useContext(authContext)

  const accordionRef = useRef(null);
  useLayoutEffect(() => {
    resetOpenLast();

    if(openLast && isLast) {
      document.querySelector('#mainContainer').scrollTo({
            top: accordionRef.current.offsetTop,
            behavior: "smooth"
          })
    }
  }, [])

  useEffect(() => {
    getImgSize(fileUrl)
  }, [fileUrl])

  const firstUpdate = useRef(true);
  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      userQRCodeUpload(fileImage, place.visitorPlaceID)
    }

  }, [fileImage])

  useEffect(() => {
    if(!!logo) {
      setFileUrl(logo)
    }
  }, [logo])



  useEffect(() => {
    setNameDebounce(tmpName)
  }, [tmpName])

  useEffect(() => {
    !!nameDebounce && name !== nameDebounce && setPlace({...place, name: nameDebounce })
  }, [nameDebounce])

  useEffect(() => {
    setTmpName(name)
  }, [name])



  useEffect(() => {
    setDescriptionDebounce(tmpDescription)
  }, [tmpDescription])

  useEffect(() => {
    !!descriptionDebounce && description !== descriptionDebounce && setPlace({...place, description: descriptionDebounce })
  }, [descriptionDebounce])

  useEffect(() => {
    setTmpDescription(description)
  }, [description])



  useEffect(() => {
    setColorDebounce(tmpColor)
  }, [tmpColor])

  useEffect(() => {
    !!colorDebounce && color !== colorDebounce && setPlace({...place, color: colorDebounce })
  }, [colorDebounce])

  useEffect(() => {
    setTmpColor(color)
  }, [color])


  useEffect(() => {
    setExpiredDebounce(tmpExpireTime)
  }, [tmpExpireTime])

  useEffect(() => {
    expiredDebounce !== false && setPlace({...place, expired: expiredDebounce })
  }, [expiredDebounce])

  useEffect(() => {
    setTmpExpireTime(expired)
  }, [expired])


  useEffect(() => {
    setHasCustomDebounce(tmpHasCustom)
  }, [tmpHasCustom])

  useEffect(() => {
    setPlace({...place, hasCustom: hasCustomDebounce })
  }, [hasCustomDebounce])

  useEffect(() => {
    setTmpHasCustom(hasCustom)
  }, [hasCustom])


  useEffect(() => {
    setCustomDebounce(tmpCustom)
  }, [tmpCustom])

  useEffect(() => {
    !!customDebounce && custom !== customDebounce && setPlace({...place, custom: customDebounce })
  }, [customDebounce])

  useEffect(() => {
    setTmpCustom(custom)
  }, [custom])


  const firstPlaceUpdate = useRef(true);
  useEffect(() => {
    if (firstPlaceUpdate.current) {
      firstPlaceUpdate.current = false;
    } else {
      updatePlaces(place, index)
    }
  }, [place])

  const surveyUrl = process.env.REACT_APP_SITE_URL + '/anmeldung/' + code;

  const [file, setFile] = useState({
    height: null,
    width: null,
  })

  const getImgSize = (imgSrc) => {
    let newImg = new Image();
    newImg.onload = function() {
      let height = newImg.height;
      let width = newImg.width;
      let ratio = width > height ? width / height : height / width;

      let side = Math.sqrt(11000 / ratio);
      let maxSize = Math.round(ratio * side);
      maxSize = maxSize > 140 ? 140 : maxSize;

      if (width >= height) {
        setFile({
          width: maxSize,
          height: maxSize / ratio
        });
      } else {
        setFile({
          width: maxSize / ratio,
          height: maxSize
        });
      }
    }

    newImg.src = imgSrc;
  }

  const handleColorChange = (colorHex) => {
    colorHex.length && setTmpColor(colorHex)
  }

  const handleImageUpload = (e) => {
    if(e) {
      setFileUrl(URL.createObjectURL(e[0]))
      let formData = new FormData()
      formData.append('file', e[0], 'image.jpg')
      setFileImage(formData)
    } else {
      setFileUrl(null)
      setPlace({...place, logo: null })
    }
  }

  const handleDownloadQR = () => {
    canvasToImage(document.querySelector(`#qrCanvas${code}`), {
      name: `${tmpName}_QRCode`,
      type: 'jpg',
    });
  }

  const handleCompanySave = (e) => {
    setTmpName(e.target.value);
  }

  const handleDescriptionSave = (e) => {
    setTmpDescription(e.target.value);
  }

  const handleCustomSave = (e) => {
    setTmpCustom(e.target.value);
  }

  const handleHasCustomSave = (e) => {
    setTmpHasCustom(e.target.checked);
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleEpireTimeSave = (e) => {
    let expTime = e.target.value;
    let controlType = e.target.type;
    if(controlType === 'checkbox') {
      setTmpExpireTime(e.target.checked ? 4 : null);
    } else {
      if(expTime >= 1 && expTime <= 48 || expTime.length === 0)
        setTmpExpireTime(Math.round(parseInt(expTime)));
    }
  };

  const handleEpireTimeBlur = (e) => {
    let expTime = e.target.value;
    if(!expTime.length) {
      setTmpExpireTime(null);
    }
  }

  const handleDeletePlace = async () => {
    setBluring(true)
    await deletePlace(visitorPlaceID);
    setOpenDialog(false);
    setBluring(false)
  };

  const renderBody = () => {
    return <div className={classes.bodyWrap}>
      {index === undefined && <Typography variant="h5" component="h1" gutterBottom>{tmpName ? tmpName : 'Neue Betriebsstätte'} </Typography>}
      <Divider className={classes.divider} />

      <div className={classes.descWrap}>
        <Typography variant="body2" gutterBottom className={classes.desc}>Passen Sie hier den QR-Code für die Anmeldung Ihrer Besucher an: </Typography>
        {accordion && <Tooltip title="Betriebsstätte löschen">
          <Fab color="primary" size="small" onClick={handleOpenDialog} className={classes.buttonDelete}>
            <DeleteOutlineIcon />
          </Fab>
        </Tooltip>}
      </div>

      <Grid container spacing={4} wrap="wrap">
        <Grid item xs={12} className={classes.gridInputWrap}>
          <Input
              value={tmpName}
              name="name"
              labelText="Name des Betriebs"
              onChange={handleCompanySave}
              fullWidth />
        </Grid>
        <Grid item xs={12} className={classes.gridInputWrap}>
          <Input
              value={tmpDescription}
              name="description"
              labelText="Kommentar z.B. Adresse (wird nicht angezeigt)"
              onChange={handleDescriptionSave}
              fullWidth />
        </Grid>
        <Grid item xs={12} md={7} className={classes.gridOptionsWrap}>
          <Grid item xs={12} sm={9} className={classes.grid}><ColorButton onChange={handleColorChange} userColor={tmpColor || '#000'} /></Grid>

          <Grid item xs={12} sm={9} className={classes.grid}><UploadDropzone onChange={handleImageUpload} defaultImage={fileUrl}/></Grid>

          <Grid item xs={12} sm={12} container alignItems="center" className={classes.grid}>
            <FormControlLabel
              className={classes.expireLabel}
              control={<Checkbox 
                  color="primary" 
                  checked={!!tmpExpireTime} 
                  onChange={handleEpireTimeSave}
                />
              }
              label={tmpExpireTime === null ? 'Besucher automatisch abmelden' : 'Besucher automatisch abmelden nach'}
          />
            {tmpExpireTime !== null && <React.Fragment>
                <Input
                  className={classes.expireInput}
                  value={tmpExpireTime}
                  name="expired"
                  onChange={handleEpireTimeSave}
                  onBlur={handleEpireTimeBlur}
                  type="number"
                  disabled={tmpExpireTime === null}
                  inputProps={{
                    min: 1,
                    max: 48,
                    //pattern: '^-?\d+$'
                  }}
                /> 
                <div className={classes.expireInputDesc}>Stunden</div>
            </React.Fragment> }
          </Grid>

          <Grid item xs={12} sm={12} container alignItems="center" className={classes.grid}>
            <FormControlLabel
              className={classes.customLabel}
              control={<Checkbox
                color="primary"
                checked={tmpHasCustom}
                onChange={handleHasCustomSave}
              />
              }
              label="Zusätzliches Eingabefeld einfügen (z.B. Tischnummer, Raum, Name der besuchten Person)"
            />
            {tmpHasCustom && <React.Fragment>
              <Input
                className={classes.custom}
                value={tmpCustom}
                name="custom"
                onChange={handleCustomSave}
                onBlur={handleCustomSave}
                labelText={"Beschriftung des Eingabefelds"}
                fullWidth
              />
            </React.Fragment> }
          </Grid>

          {matches && <Grid item xs={12} sm={9} className={classes.gridLink}>
            <Link target="_blank" to={`/anmeldung/${code}?isTest=true`} className={classes.qrLink} component={NavLink}><OpenInNewIcon className={classes.qrLinkIco}/> Anmeldeformular testen</Link>
          </Grid>}
          {!matches && <Grid item xs={12} sm={9} className={classes.gridLink}>
            <Link href={`/survey-test-device/${code}`} target="_blank" className={classes.qrLink}><PhoneIphoneIcon className={classes.qrLinkIco}/> Anmeldeformular testen</Link>
          </Grid>}
        </Grid>
        <Grid item xs={12} md={5} className={classes.qrGrid}>
          <div className={styles.qrWrap}>
            <QRCodeGen
                value={surveyUrl}
                size={260}
                fgColor={!!tmpColor ? tmpColor : '#000'}
                level={"H"}
                includeMargin={false}
                renderAs={"canvas"}
                imageSettings={fileUrl && {
                  src: fileUrl,
                  x: null,
                  y: null,
                  height: file.height,
                  width: file.width,
                  excavate: true,
                }}
            />
          </div>
          <div style={{visibility: 'hidden', position: 'absolute', right: 99999999}}>
            <QRCodeGen
                id={`qrCanvas${code}`}
                value={surveyUrl}
                size={260 * 2.5}
                fgColor={!!tmpColor ? tmpColor : '#000'}
                level={"H"}
                includeMargin={false}
                renderAs={"canvas"}
                imageSettings={fileUrl && {
                  src: fileUrl,
                  x: null,
                  y: null,
                  height: file.height * 2.5,
                  width: file.width * 2.5,
                  excavate: true,
                }}
            />
          </div>
          <Button
              disabled={!tmpName || !userInfo?.company?.length}
              className={classes.button}
              variant="contained"
              color="primary"
              size="large"
              onClick={handleDownloadQR}
          >QR-CODE HERUNTERLADEN</Button>
        </Grid>
      </Grid>
      <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Sind Sie sicher, dass Sie die Betriebsstätte "{tmpName}, {tmpDescription}" löschen wollen? </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Nach der Löschung wird der für diese Betriebsstätte generierte QR-Code deaktiviert, die Kontaktdaten der durch diesen QR-Code neu angemeldeten Gäste werden nicht mehr gespeichert.
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleDeletePlace} color="primary" autoFocus>
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
      {bluring && <div className={classes.blurring} />}
    </div>
  }

  return <React.Fragment>
    {!accordion ? renderBody() : <Accordion ref={accordionRef} className={classes.accordion} defaultExpanded={index === 0 || openLast && isLast} >
      <AccordionSummary
          className={clsx(classes.accordionSummary, {'no-desc': !tmpDescription})}
          expandIcon={<ExpandMoreIcon />}
      >
        <div className={classes.summaryContentWrap}>
          <div className={classes.summaryContentBody}>
            <Typography variant="h5" component="h5" className={classes.headingTitle}>{tmpName ? tmpName : 'Neue Betriebsstätte'}</Typography>
            {tmpDescription && <Typography className={clsx('headingDesc', classes.headingDesc) }>{tmpDescription}</Typography>}
          </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {renderBody()}
      </AccordionDetails>
    </Accordion>}
  </React.Fragment>
}

export default QRForm;
