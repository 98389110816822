import {
  USER_LOGIN,
  SHOW_LOADER,
  HIDE_LOADER,
  USER_LOGOUT,
  SHOW_PRELOADER,
  USER_INFO,
  USER_UPDATE,
  USER_UPDATED,
  USER_CHANGE_LANG,
  USER_CHECK,
  GET_EXTERNAL_LOGIN,
  HIDE_XML_LOADER,
  SHOW_XML_LOADER,
  SET_COOKIE_ERROR
} from '../types';

const handlers = {
  [SHOW_LOADER]: (state) => ({ ...state, loading: true }),
  [HIDE_LOADER]: (state) => ({ ...state, loading: false }),
  [SHOW_XML_LOADER]: (state) => ({ ...state, XMLLoading: true }),
  [HIDE_XML_LOADER]: (state) => ({ ...state, XMLLoading: false }),
  [SHOW_PRELOADER]: (state) => ({ ...state, preLoading: true }),
  [USER_CHECK]: (state) => ({ ...state, userChecking: true }),
  [USER_LOGIN]: (state) => ({
    ...state,
    userAuth: true,
    loading: false,
    preLoading: false,
    userCheck: true,
  }),
  [USER_LOGOUT]: (state) => ({
    ...state,
    userAuth: false,
    loading: false,
    preLoading: false,
    userCheck: true,
  }),
  [USER_INFO]: (state, { payload }) => ({
    ...state,
    userInfo: { ...payload },
    loading: false,
    preLoading: false,
  }),
  [USER_UPDATE]: (state, { payload }) => ({
    ...state,
    userInfo: { ...state.userInfo, ...payload },
    loading: false,
    preLoading: false,
  }),
  [USER_UPDATED]: (state) => ({ ...state, loading: false }),
  [USER_CHANGE_LANG]: (state, { payload }) => ({
    ...state,
    userInfo: { ...state.userInfo, language: payload },
    loading: false,
    preLoading: false,
  }),
  [GET_EXTERNAL_LOGIN]: (state, { payload }) => ({
    ...state,
    externalLogins: payload,
    loading: false,
    preLoading: false,
  }),
  [SET_COOKIE_ERROR]: (state) => ({
    ...state,
    cookieError: true,
  }),
  DEFAULT: (state) => state,
};

const authReducer = (state, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  return handle(state, action);
};

export default authReducer;
