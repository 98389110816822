import React, { useState, useEffect, useContext } from 'react';
import {Grid, Link, Typography} from '@material-ui/core';
import clsx from 'clsx';
import {useDebounce} from '@react-hook/debounce'
import { makeStyles } from '@material-ui/core/styles';
import {  Accordion, AccordionSummary, AccordionDetails, Paper } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import authContext from '../../../src/context/auth/authContext';
import placesContext from '../../../src/context/places/placesContext';
import Meta from '../../components/Meta/Meta';
import QRForm from './components/QRForm/QRForm';
import PhoneIphoneIcon from "@material-ui/icons/PhoneIphone";
import AddIcon from '@material-ui/icons/Add';
import CircularProgress from "@material-ui/core/CircularProgress";
import GetAppOutlinedIcon from "@material-ui/icons/GetAppOutlined";

const useStyles = makeStyles((theme) => ({
    paper: {
        minHeight: 200,
        padding: '24px 60px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: '14px 20px',
        },
    },
    divider: {
        marginBottom: 20,
    },
    desc: {
        marginBottom: 40,
    },
    grid: {
        marginBottom: 20,
    },
    gridInputWrap: {
        paddingBottom: '0!important',
        [theme.breakpoints.down('sm')]: {
            order: 1
        },
    },
    gridOptionsWrap: {
        [theme.breakpoints.down('sm')]: {
            order: 3
        },
    },
    qrGrid: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
             order: 2,
            alignItems: 'flex-start',
        },
    },
    gridLink: {
      paddingTop: 40
    },
    button: {
        width: 260
    },
    buttonSave: {
        position: 'relative'
    },
    addLink: {
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'flex-start',
        },
    },
    qrLinkIco: {
        marginRight: 14
    },
    addLinkIcoProgress: {
        marginRight: 14
    },
}));

const QRCode = () => {
  const classes = useStyles();
  const { getPlaces, newPlace, data: places, loadingAddPlace} = useContext(placesContext)

  useEffect(() => {
      getPlaces()
  }, [])

  const handleAddPlace = async () => {
      await newPlace({})
  }
  return (
    <Grid container spacing={4} wrap="wrap">
      <Meta
        title="QR-Codes"
        subTitle="Digitale Gästeliste"
      />
        {places.length === 1 && <Grid item xs={12}>
            <Paper className={classes.paper}><QRForm {...places[0]} accordion={false}/></Paper>
        </Grid>}

        {places.length > 1 &&
            <Grid item xs={12}>
                {places.map( (item, index) => {
                    return <QRForm {...item} index={index} accordion isLast={index === places.length - 1}/>
                })}
            </Grid>
        }
        <Grid item xs={9} className={classes.gridAddLink}>
            <Link className={classes.addLink} onClick={handleAddPlace}>
                {loadingAddPlace ? <CircularProgress color="primary" size={24} className={classes.addLinkIcoProgress} /> : <AddIcon className={classes.qrLinkIco}/> }
                Betriebsstätte Hinzufügen</Link>
        </Grid>
    </Grid>
  );
};

export default QRCode;
