import React, { useContext } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';

import styles from './MainAuthContainer.module.scss';
import LogoIcon from '../../components/Icons/Icons';
import AuthContext from '../../context/auth/authContext';

const GlobalCss = withStyles({
  '@global': {
    body: {
      backgroundColor: '#e31c5e!important',
    },
  },
})(() => null);

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '20px',
    padding: '24px 38px',
    marginBottom: theme.spacing(2),
  },
  logo: {
    fontSize: '86px',
    marginBottom: '24px',
  },
  progressBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  link: {
    color: '#fff',
    textDecoration: 'underline',
  },
}));

const MainAuthContainer = withRouter((props) => {
  const classes = useStyles();
  const { loading } = useContext(AuthContext);
  const { t } = useTranslation();

  const renderMessage = () => {
    if (props.match.path === '/sign-in' || props.match.path === '/register') {
      return (
          <div className={styles.message}>
            {t('mainAuthContainer.message1')}
            <Link to="/sign-in" component={NavLink} className={classes.link}>{t('mainAuthContainer.link')}</Link>
            {t('mainAuthContainer.message2')}
          </div>
      );
    }
  };

  return (
    <main className={styles.main}>
      {loading && <LinearProgress className={classes.progressBar} variant="query" color="primary" />}
      <GlobalCss />
      <Container component="main" maxWidth="sm">
        <div className={styles.logo}>
          <LogoIcon
            className={classes.logo}
            htmlColor="#fff"
            fontSize="large"
          />
          <div>QUESTIONSTAR</div>
          <div className={styles.logo_desc}>Digitale Gästeliste</div>
        </div>
        <Paper className={classes.paper}>
          { props.children }
        </Paper>
        {renderMessage()}
      </Container>
    </main>
  );
});

MainAuthContainer.propType = {
  children: PropTypes.element.isRequired,
};

export default MainAuthContainer;
