import React, { useContext, useLayoutEffect, useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Link } from '@material-ui/core';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CircularProgress from '@material-ui/core/CircularProgress';
import styles from './RespondentsVis.module.scss';
import MainChart from "../MainChart/MainChart";
import RespondentsTable from "../RespondentsTable/RespondentsTable";
import placesContext from "../../../../context/places/placesContext";


const useStyles = makeStyles(() => ({
    gridLink: {
        paddingTop: 40
    },
    qrLink: {
        cursor: 'pointer',
        display: 'inline-flex',
        alignItems: 'center'
    },
    qrLinkIco: {
        color: 'rgba(0, 0, 0, 0.54)',
        marginRight: 14
    },
    qrLinkIcoProgress: {
        marginRight: 14
    }
}));

const RespondentsVis = ({ place, openAccordion, index }) => {
  const classes = useStyles();
  const { getUserRespondentsPlace, getpiplinePlaceExportXLSX } = useContext(placesContext);
  const [ respondents, setRespondents ] = useState([]);
  const [ XMLLoading, setXMLLoading ] = useState(false);

  useLayoutEffect(() => {
      let isMounted = true;
      index === 0 && getRespondents(isMounted);
      return () => { isMounted = false };
  }, []);

  useEffect(() => {
      let isMounted = true;
      openAccordion && getRespondents(isMounted);
      return () => { isMounted = false };
  }, [openAccordion]);

  const getRespondents = async (isMounted) => {
      const res = await getUserRespondentsPlace(place.visitorPlaceID)
      isMounted && setRespondents(res.data.data)
  }

  const handleDownloadList = async () => {
      setXMLLoading(true)
      await getpiplinePlaceExportXLSX(place.visitorPlaceID)
      setXMLLoading(false)
  }
  return <React.Fragment>
      <MainChart className={styles.mainChart} dataRespondents={respondents || []}/>
      <RespondentsTable handleSetRespondents={setRespondents} placeCode={place.code} customField={place.custom} hasCustom={place.hasCustom} placeId={place.visitorPlaceID} data={respondents || []}/>
      <Grid item xs={9} className={classes.gridLink}>
          <Link className={classes.qrLink} onClick={handleDownloadList}>
              {XMLLoading ? <CircularProgress color="primary" size={24} className={classes.qrLinkIcoProgress} /> : <GetAppOutlinedIcon className={classes.qrLinkIco}/> }
              Gästeliste für die gewählte Periode herunterladen</Link>
      </Grid>
  </React.Fragment>
};

export default RespondentsVis;
