import React, { useReducer } from 'react';
import PropTypes from 'prop-types';

import {
  getUserPayments,
} from '../../api/payments';

import payoutsReducer from './payoutsReducer';
import PayoutsContext from './payoutsContext';
import { GET_PAYMENTS } from '../types';

const PayoutsState = ({ children }) => {
  const initialState = {
    data: [],
  };

  const [state, dispatch] = useReducer(payoutsReducer, initialState);

  const getPayments = async () => {
    const res = await getUserPayments();
    dispatch({ type: GET_PAYMENTS, payload: res.data.data });
  };

  return (
    <PayoutsContext.Provider value={{
      getPayments,
      data: state.data,
    }}
    >
      {children}
    </PayoutsContext.Provider>
  );
};

PayoutsState.propType = {
  children: PropTypes.element.isRequired,
};

export default PayoutsState;
