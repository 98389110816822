import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const Copyright = () => (
  <Typography variant="body2" color="textSecondary" align="center">
    {'© '}
    {new Date().getFullYear()}
    {' '}
    QUESTIONSTAR
  </Typography>
);

export default Copyright;
