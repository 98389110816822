import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ContactForm from './components/ContactForm/ContactForm';
import styles from './Account.module.scss';
import Meta from '../../components/Meta/Meta';

const useStyles = makeStyles((theme) => ({
  paper: {
    minHeight: 200,
    padding: '24px 60px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '14px 20px',
    },
  },
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  button: {
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  divider: {
    marginBottom: 20,
  },
  payoutsForm: {
    marginBottom: theme.spacing(6),
  },
}));

const Account = () => {
  const classes = useStyles();

  return (
    <Grid container spacing={4}>
      <Meta
        title="Kontaktdaten Ihres Betriebs"
        subTitle="Digitale Gästeliste"
      />
      <Grid item xs={12}>
        <Paper className={classes.paper} variant="outlined">
          <Grid item xs={12} className={classes.formControl}>
            <Typography variant="h5" component="h1" gutterBottom>Meine Kontaktdaten</Typography>
            <Divider className={classes.divider} />
            <Typography variant="body2" gutterBottom className={classes.desc}>Diese Daten werden benötigt, um im Fall eines begründeten Verdachts auf Kontakt mit einem Corona-Fall
              Sie und Ihre Gäste möglichst schnell darüber zu benachrichtigen. </Typography>
            <ContactForm />
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Account;
