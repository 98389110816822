import { get, post, put, del } from './restApi';

const rewardApiUrl = process.env.REACT_APP_REPORT_API_URL;

export const getUserPlaces = () => get('Visitor/Places');
export const newUserPlaces = (data) => post('Visitor/Places', data);
export const getRespondentsPlace = (id) => get(`Visitor/Places/Respondents/${id}`);
export const qrUpload = (data, id) => post(`Visitor/Places/Upload/${id}`, data);
export const updateUserPlaces = (data) => put(`Visitor/Places/${data.visitorPlaceID}`, { ...data });
export const deleteUserPlaces = (id) => del(`Visitor/Places/${id}`);
export const addRespondentsPlace = (data) => post(`Visitor/Survey/`, data);
export const closeRespondentsPlace = (id) => del(`Visitor/Survey/${id}`);
export const piplinePlaceExportXLSX = (id) => get(`${rewardApiUrl}Visitor/Visitors/Place/Pipeline/XLSX/${id}`, { responseType: 'blob' });