import React from 'react';
import {
  FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, IconButton
} from '@material-ui/core';
import { ArrowDropUp, ArrowDropDown } from '@material-ui/icons';
import useLabelWidth from '../../hooks/useLabelWidth';

import styles from './Input.module.scss';

const Input = ({
  className, 
  value, 
  name, 
  labelText, 
  helperText, 
  helperTextError, 
  onChange,
  onBlur,
  startAdornment, 
  error, 
  required, 
  fullWidth, 
  placeholder, 
  type,
  disabled,
  inputProps
}) => {
  const [
    labelWidth,
    labelRef,
  ] = useLabelWidth();

  const onChangeUp = () => {
    onChange({ target: {
      value: value + 1,
    } });
  };

  const onChangeDown = () => {
    onChange({ target: {
      value: value - 1,
    } });
  };

  return (
    <FormControl disabled={disabled} fullWidth={fullWidth} required={required} className={className} variant="outlined" error={error}>
      <InputLabel ref={labelRef} htmlFor={`input-${name}`}>{labelText}</InputLabel>
        <OutlinedInput
          value={value || ''}
          id={`input-${name}`}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          labelWidth={labelText && labelWidth}
          startAdornment={startAdornment}
          placeholder={placeholder}
          inputProps={inputProps}
        />
        
        {type === 'number' && <div className={styles['arrow-icons']}>
          <div className={styles['arrow-icons_up']}>
              <IconButton size="small" onClick={onChangeUp}>
                <ArrowDropUp fontSize="inherit" />
              </IconButton>
            </div>
            <div className={styles['arrow-icons_down']}>
              <IconButton size="small" onClick={onChangeDown}>
                <ArrowDropDown fontSize="inherit" />
              </IconButton>
          </div>
        </div>}
      
      {helperTextError && <FormHelperText error>{helperTextError}</FormHelperText>}
      {helperText && <FormHelperText error={false}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default Input;
