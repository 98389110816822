import React, { useContext, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  InputAdornment,
  Divider, Button,
} from '@material-ui/core';
import {
  Business,
} from '@material-ui/icons';

import Input from '../../../../components/Input/Input';
import ConfirmationForm from '../../../../components/ConfirmationForm/ConfirmationForm';
import SelectCountries from '../../../../components/SelectCountries/SelectCountries';
import AuthContext from '../../../../context/auth/authContext';
import useForm from '../../../../hooks/useForm';
import validate from '../../../../helpers/validateContactForm';
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(2),
    marginLeft: 0,
    minWidth: '100%',
  },
  firstInput: {
    paddingRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
          paddingRight: 0,
      },

},
  button: {
    margin: theme.spacing(2),
    marginLeft: 0,
  },
  divider: {
    marginBottom: 20,
  },
  checkbox: {
    marginBottom: theme.spacing(4),
  },
}));


const ContactForm = () => {
  const { t } = useTranslation();
  const [ showButton, setShowButton ] = useState(false);
  const classes = useStyles();
  const {
    saveUser,
    userInfo,
  } = useContext(AuthContext);

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
  } = useForm(() => {
    saveUser(values);
    setShowButton(false);
  }, validate, {
    company: userInfo.company,
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    address: userInfo.address,
    postalCode: userInfo.postalCode,
    bundesland: userInfo.bundesland,
    city: userInfo.city,
    phone: userInfo.phone
  });

    useEffect(() => {
        getIsSaved()
    }, [values])

  const getIsSaved = () => {
      if(values.company !== userInfo.company ||
          values.firstName !== userInfo.firstName ||
          values.lastName !== userInfo.lastName ||
          values.address !== userInfo.address ||
          values.postalCode !== userInfo.postalCode ||
          values.bundesland !== userInfo.bundesland ||
          values.city !== userInfo.city ||
          values.phone !== userInfo.phone) {
          setShowButton(true);
      } else {
          setShowButton(false)
      }
  }

  return (
    <Grid container className={classes.formContainer}>
      <Grid item xs={12}>
        <Input
          required
          className={classes.formControl}
          value={values.company || ''}
          name="company"
          labelText="Name des Betriebs:"
          onChange={handleChange}
          helperTextError={errors.company}
          error={!!errors.company}
          startAdornment={(
            <InputAdornment position="start">
              <Business color="disabled" />
            </InputAdornment>
              )}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          className={clsx(classes.formControl, classes.firstInput)}
          value={values.firstName || ''}
          name="firstName"
          labelText="Vorname des Inhabers:"
          onChange={handleChange}
          error={!!errors.firstName}
          helperTextError={errors.firstName}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          className={classes.formControl}
          value={values.lastName || ''}
          name="lastName"
          labelText="Nachname des Inhabers:"
          onChange={handleChange}
          error={!!errors.lastName}
          helperTextError={errors.lastName}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <Input
          className={classes.formControl}
          value={values.address || ''}
          name="address"
          labelText="Strasse und Hausnummer des Betriebs:"
          onChange={handleChange}
          error={!!errors.address}
          helperTextError={errors.address}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          className={clsx(classes.formControl, classes.firstInput)}
          value={values.postalCode || ''}
          name="postalCode"
          labelText="PLZ:"
          onChange={handleChange}
          error={!!errors.postalCode}
          helperTextError={errors.postalCode}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Input
          className={classes.formControl}
          value={values.city || ''}
          name="city"
          labelText="Ort:"
          onChange={handleChange}
          error={!!errors.city}
          helperTextError={errors.city}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectCountries
          className={clsx(classes.formControl, classes.firstInput)}
          value={values.bundesland}
          name="bundesland"
          labelText="Bundesland: "
          onChange={handleChange}
          error={!!errors.bundesland}
          helperTextError={errors.bundesland}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} />
      <Grid item xs={12} sm={6}>
        <Input
          className={clsx(classes.formControl, classes.firstInput)}
          value={values.phone || ''}
          name="phone"
          labelText="Telefonummer des Betriebs:"
          onChange={handleChange}
          error={!!errors.phone}
          helperTextError={errors.phone}
          placeholder="+49 151 23456789"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6} />
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
      <Grid item xs={12}>
          <Button
              disabled={!showButton}
          className={classes.button}
          size="large"
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
            Kontaktdaten speichern
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
