import { useState, useEffect } from 'react';

const useForm = (callback, validate, initState) => {
  const [values, setValues] = useState({ ...initState });
  const [errors, setErrors] = useState({});
  const [target, setTarget] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const resetValues = () => {
    setValues({ ...initState });
  };

  useEffect(() => {
    let ignore = false;
    if (Object.keys(errors).length === 0 && isSubmitting) {
      if (!ignore) callback(resetValues);
    }
    return () => { ignore = true; };
  }, [errors]);

  useEffect(() => {
    if (errors[target.name]) setErrors(validate(values));
  }, [values]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setIsSubmitting(true);
    setErrors(validate(values));
  };

  const handleChange = (event) => {
    setIsSubmitting(false);
    setValues({
      ...values,
      [event.target.name]: event.target.type !== 'checkbox'
        ? event.target.value : event.target.checked,
    });
    setTarget(event.target);
  };


  return {
    handleChange,
    handleSubmit,
    values,
    errors,
  };
};

export default useForm;
