import React, {
  Fragment, useState, useContext, useEffect, useLayoutEffect
} from 'react';
import clsx from 'clsx';
import { NavLink, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  List, ListItem, ListItemText, ListItemIcon, SvgIcon,
} from '@material-ui/core';
import {
  InsertChart as ChartLineIcon,
  SupervisorAccountOutlined as CustomersIcon,
  Link as LinkIcon,
} from '@material-ui/icons';
import { mdiQrcode } from '@mdi/js';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import CreditCardOutlinedIcon from '@material-ui/icons/CreditCardOutlined';
import LocalAtmRoundedIcon from '@material-ui/icons/LocalAtmRounded';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import AuthContext from '../../../../context/auth/authContext';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  item: {
    '&:hover': {
      backgroundColor: '#fce7ee',
      borderLeft: '6px solid rgba(227,28,94, .3)',
      paddingLeft: 10,
    },
    '&.Mui-selected': {
      backgroundColor: '#fce7ee',
      borderLeft: '6px solid #e31c5e',
      paddingLeft: 10,
    },
    '&.active': {
      backgroundColor: '#fce7ee',
      borderLeft: '6px solid #e31c5e',
      paddingLeft: 10,
    },
  },
  listItemIcon: {
    minWidth: 46,
  },
  itemText: {
    '& > *': {
      'text-overflow': 'ellipsis',
      overflow: 'hidden',
    },
  },
  subItem: {
    '&.logout': {
      color: theme.palette.error.main,
    },
    '&:hover': {
      paddingLeft: 10,
      backgroundColor: '#fce7ee',
      borderLeft: '6px solid rgba(227,28,94, .3)',
    },
    '&.active': {
      backgroundColor: '#fce7ee',
      borderLeft: '6px solid #e31c5e',
      paddingLeft: 10,
    },
  },
  logOutIcon: {
    color: theme.palette.error.main,
  },
}));

// TODO Разделить логику пунктов меню при необходимости
const ListItems = withRouter(({ navItems, isOpen, match }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { logOut } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if ((match.url === '/profile' || match.url === '/account') && !open) {
      setOpen(true);
    }
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setOpen(false);
    }

    if ((match.url === '/profile' || match.url === '/account') && !open) {
      setOpen(true);
    }
  }, [isOpen]);

  const handlerLogOut = () => {
    logOut();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlers = (key) => {
    switch (key) {
      case 'profile':
        return (e) => {
          if (isOpen) {
            setOpen(!open);
          } else {
            setAnchorEl(e.currentTarget);
          }
        };
      default:
        return (e) => {
          if (isOpen) {
            setOpen(false);
          }
        };
    }
  };

  return (
    <div>
      <List component="nav">
        {navItems.map((item) => (
          <Fragment key={item.key}>
            <ListItem
              active={item.key}
              className={classes.item}
              key={item.key}
              button
              alignItems="center"
              component={item.key === 'collateral' ? 'button' : NavLink}
              href={item.link}
              to={item.key === 'collateral' ? false : item.link}
              target={item.key === 'collateral' ? '_blank' : false}
              exact={item.name === 'Dashboard' ? 'true' : 'false'}
              activeClassName="Mui-selected"
              onClick={handlers(item.key)}
            >
              <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} className={classes.itemText} />
            </ListItem>
          </Fragment>
        ))}
        <ListItem
            className={clsx(classes.subItem, 'logout')}
            onClick={handlerLogOut}
            button
        >
          <ListItemIcon className={classes.listItemIcon}>
            <ExitToAppIcon className={classes.logOutIcon} />
          </ListItemIcon>
          <ListItemText primary="Abmelden" />
        </ListItem>
      </List>
    </div>
  );
});

const MainNavigation = ({ className, isOpen, userName }) => {
  const { t } = useTranslation();

  const navItems = [{
    name: userName,
    link: '/account',
    key: 'account',
    icon: <AccountCircleOutlinedIcon />,
  },
  {
    name: 'QR-Codes',
    link: '/qr-codes',
    key: 'qr-codes',
    icon: <SvgIcon><svg><path d={mdiQrcode} /></svg></SvgIcon>,
  },
  {
    name: "Gästelisten",
    link: '/',
    key: 'Gästelisten',
    icon: <ChartLineIcon />,
  },

  ];

  return (
    <div className={className}>
      <ListItems navItems={navItems} isOpen={isOpen}
      />
    </div>
  );
};

ListItems.propType = {
  itemsTop: PropTypes.array.isRequired,
  itemsMid: PropTypes.array.isRequired,
  itemsBottom: PropTypes.array.isRequired,
  match: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
};

MainNavigation.propType = {
  className: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
};

export default MainNavigation;
